<template>
  <section class="modal">
    <div class="modal__wrapper">
      <div class="modal__container .bounce-5">
        <slot name="header"></slot>
        <slot name="body"></slot>
        <slot name="footer">
          <div class="modal__buttons">
            <button type="button" class="modal__button submit" @click="$emit('submit')">Si, enviar</button>
            <button class="modal__button cancel" @click="$emit('cancel')">No, cancelar</button>
          </div>
        </slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  color: $letter_modal;
  &__wrapper,
  &__buttons {
    @include Flex(row);
  }
  &__wrapper {
    width: 100%;
    height: 100%;
    padding: $mpadding;
    position: relative;
  }
  &__container {
    @include Flex(column);
    gap: $mpadding;
    max-width: 512px;
    height: auto;
    max-height: 90vh;
    padding: $mpadding * 1.5;
    border-radius: 5px;
    background-color: $white;
    box-shadow: $dshadow;
    overflow-y: auto;
    animation: show-in 300ms;
  }
  &__buttons {
    gap: $mpadding/1.5;
  }
  &__button {
    padding: 10px 17.6px;
    outline: none;
    border: none;
    font-size: 1em;
    border-radius: $border-radius-bootstrap;
    color: $white;
    cursor: pointer;
    &:hover {
      opacity: $opacity-button;
    }
    &:focus {
      box-shadow: 0 0 0 3px rgba(100, 150, 200, 0.5);
    }
    &:active {
      background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    }
    &.submit {
      background-color: $primary_color_modal;
    }
    &.cancel {
      background-color: $primary_color;
    }
  }
}
</style>
